* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 1.05rem;
  line-height: 1.6;
  scroll-behavior: smooth;
}

.mobile-header-links {
  padding: 9px 0;
  display: block;
  color: #1b1d21;
  font-weight: 600;
  border-bottom: 1px solid #e5e5e5;
  text-decoration: none;
}

.mobile-header-links:hover {
  color: #43baff;
}

.home-page-solutions-alignment {
  display: flex;
  flex-direction: column;
}

.home-page-solutions-alignment:nth-child(even) {
  flex-direction: column-reverse;
}

.home-page-solutions-alignment:nth-child(even) .solution-desc-box {
  position: relative;
  top: 9rem;
}

@media(max-width: 992px){
  .home-page-solutions-alignment:nth-child(even) .solution-desc-box {
    /* position: relative; */
    top: auto;
  }
}

.service-page-banner {

  background: url(./images/background/service-banner.webp)no-repeat;
  /* background-position: center; */
  background-size: cover;
}

.solution-desc-box {
  padding: 2rem;
  margin: 0 2rem;

}

.transform-up {
  transform: translateY(-75px);
}

.transform-down {
  transform: translateY(75px);

}

.solution-desc-box h5,
.icon-box-s3.i7 h5 {
  font-size: 1.8rem;
  font-weight: 600;
}

.solution-desc-box .left-0 {
  left: 0
}

@media (max-width:992px) {
  .transform-up {
    transform: none
  }

  .transform-down {
    transform: none
  }

  .solution-desc-box {
    margin: 0
  }

}

p.fs-15 {
  font-size: 1.5rem;
}

.main-navigation ul li li a:hover {
  color: #ffffffa1
}

.service-content-text {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 2rem;
}

.service-content-box .service-content-text .checkIcon {
  position: absolute;
  display: inline-block;
  height: 22px;
  width: 22px;
  line-height: 22px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
  text-align: center;
  color: #f10c51;
  border-radius: 50px;
  top: 4px;
  left: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.service-content-text h4 {
  font-size: 20px;
}

.contact-message form input,
.contact-message form textarea {
  width: 100%;
  border: none;
  padding: 10px 10px;
  border-bottom: 3px solid transparent;
  background-color: #f7f7f7;
  margin-bottom: 30px;
}

.contact-info ul li {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact-info ul li:last-child {
  border-bottom: none;
}

.contact-message {
  background: #363b6b;
  padding: 3rem 2rem 1rem;
}

.contact-info .icons {
  font-size: 35px;
  color: #363b6b;
  padding-right: 0.6rem;
}

.about-us-wrapper h3 {
  font-size: 38px;
}

.bg-theme {
  background: #363b6b;
}

.btn-details .icon {
  margin-right: 8px;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
}

.btn-details .icon:before {
  font-size: 15px;
}

.btn-details:hover .icon {
  margin-right: 3px;
}

.go-to-top-btn {
  font-size: 1.5rem !important;
  width: 3rem;
  height: 3rem;
  color: #ef0e52 !important;
  line-height: 45px;
  background-color: #fff;
  position: fixed;
  bottom: 2rem;
  right: 3rem;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.2s ease-out 0s;
  outline: medium none;
  transition: all 0.3s ease-in-out;
  box-shadow: 6px 6px 13px 0px rgba(42, 67, 113, 0.2);
  text-decoration: none;
}

.go-to-top-btn:hover {
  bottom: 45px;
}


.footer-linkedin-icon {
  font-size: 2rem;
}

.icon-box-s3.i7 .btn-details{
  position: absolute;
  bottom: 18px;
}

.topbar-info li a:hover{
  color: #ef0e52 !important;
}